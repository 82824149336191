import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import ArticleSlider from "../components/article-slider"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
//import FeaturedSponsorship from "../components/featured-sponsorship"
import PageAdvert from "../components/page-advert"
import CtaSlider from "../components/cta-slider"
//import TestimonialSlider from "../components/testimonial-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/hero/hero-facility-management-v5.jpg"
//import { Iframe } from "../components/iframe"
import ReactLightbox from "../components/react-lightbox"

const IndexPage = pageContext => {
  //console.log("Home page - pageContext: ", pageContext)
  //console.log("ShelterFeeding: ", ShelterFeeding)

  const {
    //allStrapiArticle,
    //strapiGlobal,
    strapiHome,
    allStrapiGallerySection,
  } = useStaticQuery(graphql`
    query {
      allStrapiGallerySection(filter: { location: { eq: "home-page" } }) {
        nodes {
          ...StrapiGallerySection
        }
      }
      strapiGlobal {
        ...StrapiGlobal
      }
      strapiHome {
        hero {
          ...StrapiHero
        }
        switcherContent {
          ...SwitcherSections
        }
        pageAd {
          ...PageAdverts
        }
        featuredSponsorship {
          ...SponsorshipSections
        }
        testimonialSection{
          ...TestimonialCards
        }
        articleSlider {
          articles {
            ...StrapiArticles
          }
        }
        callToAction {
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            _type
            name
            url
            contactPoint {
              _type
              availableLanguage
              contactType
              telephone
            }
            sameAs
          }
        }
      }
    }
  `)

  //console.log("StrapiHome: ", strapiHome)
  //console.log("strapiGlobal: ", strapiGlobal)
  //console.log("StrapiSeo: ", strapiHome.seo)

  const structuredData = strapiHome.seo.structuredData

  //const sponsorshipSections = strapiHome.featuredSponsorship
  //console.log("Sponsorship Sections: ", sponsorshipSections)

  const callToAction = strapiHome.callToAction
  //console.log(" strapiHome callToAction: ", callToAction)

  const callToActionSections = strapiHome.callToAction.call_to_action_sections
  //console.log("strapiHome callToActionSections: ", callToActionSections)

  const articles = strapiHome.articleSlider.articles
  //console.log("strapiFaq - articleSlider articles: ", articles)

  //const articles = allStrapiArticle.nodes
  //console.log("Articles: ", articles)

  const advertimageright = strapiHome.pageAd.page_adverts[0]
  //console.log("strapiHome - pageAds: ", advertimageleft)

  const advertimageleft = strapiHome.pageAd.page_adverts[1]
  //console.log("strapiHome - pageAds: ", advertimageright)

  const testimonialCards = strapiHome.testimonialSection.testimonial_cards
  //console.log("strapiHome - testimonialCards: ", testimonialCards)

  const switcherSection = strapiHome.switcherContent.switcher_sections
  //console.log("strapiHome - switcherSection: ", switcherSection)

  const gallerySection = allStrapiGallerySection.nodes[0]
  //console.log("gallerySection: ", gallerySection)
  //console.log("gallerySection - Headings: ", gallerySection.headings.title)

  /* let galleryImages = gallerySection.images
  let remainingImages = galleryImages.splice(3)

  console.log("galleryImages: ", galleryImages)
  console.log("remainingImages: ", remainingImages) */

  //console.log("strapiHome.hero: ", strapiHome.hero)

  //const video = strapiHome.video
  //console.log("strapiHome - video: ", video.src)

  //console.log("strapiHome.seo: ", strapiHome.seo.metaTitle)

  //const imageUrl = `${strapiHome.seo.metaImage.localFile.publicURL}`

  const imageUrl = `${strapiHome.seo.metaImage.localFile.publicURL}`

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HomePage",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={strapiHome.seo.metaTitle}
        description={strapiHome.seo.metaDescription}
        image={imageUrl}
        post={false}
        metaSocial={strapiHome.seo.metaSocial}
        metaRobots={strapiHome.seo.metaRobots}
      />
      
      <Hero hero={strapiHome.hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="What We Do"
            description="We are property and facilities management company based in Greater London and surrounding counties. We provide comprehensive property and facilities management services for domestic, commercial, and industrial properties. By focusing on the facilities aspect, we deliver efficient, cost-effective, and tailored solutions to clients."
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
            <SwitcherButtonsContent switcherSections={switcherSection} />
          </div>
        </div>
      </section>

      <section className={`uk-section uk-section-muted uk-padding-remove`}>
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={advertimageright} background="uk-background-muted" />
        </div>        
      </section>

      <section className={`uk-section uk-section-default uk-padding-remove`}>
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={advertimageleft} background="uk-background-default" />
        </div>        
      </section>
      
      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonialCards}
      />

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title={gallerySection.headings.title}
            description={gallerySection.headings.description}
          />
          <div style={{ marginTop: "20px"}}>
            <ReactLightbox
              images={gallerySection.images}
              location={gallerySection.location}
              //galleryImages={galleryImages}
              //remainingImages={remainingImages}
            />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove ">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="Featured Articles"
            description="Checkout some of our articles from James Walter Raymond Ltd"
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
          <ArticleSlider articles={articles} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage
